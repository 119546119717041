import {useState} from "react";
import axios from "axios";

function ListUser(props){

    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(1);

    if(flag){
        axios.post(`http://5.101.179.244:8000/listusersadditionalproject/${props.id}`)
            .then(res => {
                setData(res.data);
                setFlag(0);
            })
    }

    return (
        <>
            {data.map(pres =>
                <p>Id user : {pres}</p>
            )}
        </>
    );
}

export default ListUser;