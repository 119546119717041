import React, {useState} from "react";
import axios from "axios";

function Card(props){

    const [flag, setFlag]           = useState(1);
    const [listProd, setListProd]   = useState([])

    if(flag){
        axios.post(`http://5.101.179.244:8000/prod/${props.id}`)
            .then(res => {
                setListProd(res.data);
                setFlag(0);
                console.log(res.data);
                //setFlag(0);
                //setListProd(res.data);
            })
    }

    return (
        <div className="card radius-10">
            <div className="card-body">

                <ul className="list-unstyled">
                    <li className="d-flex align-items-center border-bottom pb-2">
                        <img src="https://via.placeholder.com/110x110"
                             className="rounded-circle p-1 border" alt="..." width="90" height="90"/>
                        <div className="flex-grow-1 ms-3">
                            <h5 className="mt-0 mb-1">{listProd.title} <span className="badge bg-success">{props.name}</span></h5>
                            {listProd.body}
                        </div>
                        <button type="button" className="btn btn-light" data-bs-toggle="modal"
                                data-bs-target="#exampleVerticallycenteredModal">In more detail
                        </button>
                        <div className="modal fade fix-modal" id="exampleVerticallycenteredModal" tabIndex="-1"
                             aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{listProd.title} </h5>
                                        <button type="button" className="btn-close"
                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">{listProd.body}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary"
                                                data-bs-dismiss="modal">Close
                                        </button>
                                        <button type="button" className="btn btn-primary">join the project
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Card;