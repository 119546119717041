import Navigation from "./Navigation";
import Header from "./Header";
import React, {useState} from "react";
import {sha1} from 'crypto-hash';
import axios from "axios";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import Card from "./Card";

function Content(props){

    const [listProd, setListProd]   = useState([])
    const [flag, setFlag]           = useState(1);
    const [cookies, setCookie]      = useCookies(["token"]);
    const navigate                  = useNavigate();

    if(!cookies.token)
        navigate('/in');

    if(flag){
        var user_id = cookies.id;
        axios.post(`http://5.101.179.244:8000/projects/${user_id}`)
            .then(res => {
                setListProd(res.data);
                setFlag(0);
                console.log(res.data);
                //setFlag(0);
                //setListProd(res.data);
            })
    }

    return (
        <>
            <Navigation></Navigation>
            <Header></Header>
            <div className={'page-wrapper'} >
                <div className={'page-content-wrapper'}>
                    <div className={'page-content'}>
                        <h6 className="mb-0 text-uppercase">List see project</h6>

                        <hr/>
                        { listProd.map(prod =>
                            <>
                            <Card name={prod.split(":")[0]} id={prod.split(":")[1]} />

                            </>
                        )
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default Content;