
function BreadCrum(props){
    return (
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">{props.title}</div>
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                            <a href="javascript:">
                                <i className="bx bx-home-alt"></i>
                            </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{props.name}</li>
                    </ol>
                </nav>
            </div>
        </div>
    );
}

export default BreadCrum;
