import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";

function SingIn(){

    const [email, setEmail]     = useState('');
    const [pass, setPass]       = useState('');
    const navigate              = useNavigate();
    const [cookies, setCookie]  = useCookies(["token"]);

    function sendform(){
        axios.post(`http://5.101.179.244:8000/login`, { "email": email, "password_hash": pass })
            .then(res => {
                var token = res.data.token;
                var id = res.data.id;
                var fio = res.data.fio;
                if(typeof token !== "undefined")
                {
                    setCookie('token', token);
                    setCookie('id', id);
                    setCookie('fio', fio);
                    navigate('/');
                }
                else
                    alert("error login or password");
                console.log(res.data);
            })
    }

    function editEmail(e){
        var data = e.target.value;
        setEmail(data);
    }

    function editPassword(e){
        var data = e.target.value;
        setPass(data);
    }

    return (
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    <div className="col mx-auto">

                        <div className="card">
                            <div className="card-body">
                                <div className="border p-4 rounded">
                                    <div className="text-center">
                                        <h3 className="">Sign in</h3>
                                        <p>Don't have an acct?
                                            <Link to={'/up'}>Sing up</Link>
                                        </p>
                                    </div>
                                    <div className="form-body" >
                                            <div className="col-12">
                                                <label htmlFor="inputEmailAddress" className="form-label">Email
                                                    Address</label>
                                                <input type="email" className="form-control" id="inputEmailAddress"
                                                       placeholder="Email Address" value={email} onChange={editEmail}/>
                                            </div>
                                        <br />
                                            <div className="col-12">
                                                <label htmlFor="inputChoosePassword" className="form-label">Enter
                                                    Password</label>
                                                <div className="input-group" id="show_hide_password">
                                                    <input type="password" className="form-control border-end-0"
                                                           id="inputChoosePassword" value={pass} onChange={editPassword}
                                                           placeholder="Enter Password"/> <a href="javascript:;"
                                                                                            className="input-group-text bg-transparent"><i
                                                        className='bx bx-hide'></i></a>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button type="submit" className="btn btn-light" onClick={sendform} ><i
                                                        className="bx bxs-lock-open"></i>Sign in
                                                    </button>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
   
            </div>
        </div>
    );
}

export default SingIn;