import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navigation from "./Navigation";
import Header from "./Header";
import Content from "./Content";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SingIn from "./auth/SingIn";
import SingUp from "./auth/SingUp";
import Profile from "./user/Profile";
import Message from "./user/Message";
import {CookiesProvider} from "react-cookie";
import Project from "./Project";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <div class={'wrapper'}>
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<Content />} />
                    <Route path={'/message'} element={<Message />} />

                    <Route path={'/in'} element={<SingIn />} />
                    <Route path={'/up'} element={<SingUp />} />
                    <Route path={'/profile'} element={<Profile /> } />
                    <Route path={'/project'} element={<Project />} />
                </Routes>
            </BrowserRouter>
        </div>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
