import Navigation from "./Navigation";
import Header from "./Header";
import BreadCrum from "./BreadCrum";
import React, {useState} from "react";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function Project(){

    const [cookies, setCookie, deleteCookie]      = useCookies(["token"]);
    const navigate = useNavigate();

    if(!cookies.token)
        navigate('/in');

    const [data, setData] = useState({
        title:"",
        body:"",
        vacancies:"",
    });

    function valform(e){
        var title = e.target.id == 'uTitle' ? e.target.value : data.title;
        var body = e.target.id == 'uBody' ? e.target.value : data.body;
        var vacancies = e.target.id == 'uVacan' ? e.target.value : data.vacancies;

        setData({
            title: title,
            body: body,
            vacancies: vacancies,
        });
    }

    function sendform(){
        var user_id = cookies.id;
        var token = cookies.token;
        var vacan = data.vacancies.split("\n").map(String);
        axios.post(`http://5.101.179.244:8000/projects/create`, {
            "user_id": user_id,
            "title": data.title,
            "body" : data.body,
            "vacancies": vacan})
            .then(res => {
                if(typeof res.data.message !== "undefined") {
                    alert("ok");
                }
                else
                    alert("error login or password");
                console.log(res.data);
            })
    }

    return (
        <>
            <Navigation></Navigation>
            <Header></Header>
            <div className={'page-wrapper'}>
                <div className={'page-content-wrapper'}>
                    <div className={'page-content'}>
                        <BreadCrum name={'profile'} title={'User profile'}></BreadCrum>
                        <div className="container">
                            <div className="main-body">
                                <div className="row">

                                    <div className="col-lg-12">

                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="tab-content py-3">

                                                            <div className="tab-pane fade active show" id="resume" role="tabpanel">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Title</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <input type={'text'} className="form-control"
                                                                                       value={data.title} onChange={valform}
                                                                                       id="uTitle" placeholder="Develop video game Lords of the ring" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Body</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <input type={'text'} className="form-control"
                                                                                       value={data.body} onChange={valform}
                                                                                       id="uBody" placeholder="we are looking for a team to develop the game" />

                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Vacancies</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <textarea className="form-control"
                                                                                          id="uVacan" rows="3"
                                                                                          placeholder="Front React
                                                                                          Python back" value={data.vacancies} onChange={valform}>
                                                                                </textarea>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-sm-3"></div>
                                                                            <div className="col-sm-9">
                                                                                <input type="button"
                                                                                       className="btn btn-light px-4"
                                                                                       value="Save Changes" onClick={sendform}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Project;