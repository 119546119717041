import Navigation from "../Navigation";
import Header from "../Header";
import React from "react";

function Message(){
    return(
        <>
            <Navigation></Navigation>
            <Header></Header>
            <div className={'page-wrapper'}>
                <div className={'page-content-wrapper'}>
                    <div className={'page-content'}>
                        <div className="chat-wrapper">
                            <div className="chat-sidebar">
                                <div className="chat-sidebar-header">
                                    <div className="d-flex align-items-center">
                                        <div className="chat-user-online">
                                            <img src="https://via.placeholder.com/110x110" className="rounded-circle" alt="" width="45"
                                                 height="45" />
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <p className="mb-0">Rachel Zane</p>
                                        </div>
                                        <div className="dropdown">
                                            <div className="cursor-pointer font-24 dropdown-toggle dropdown-toggle-nocaret"
                                                 data-bs-toggle="dropdown"><i className="bx bx-dots-horizontal-rounded"></i>
                                            </div>
                                            <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item"
                                                                                                href="javascript:;">Settings</a>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" href="javascript:;">Help &amp; Feedback</a>
                                                <a className="dropdown-item" href="javascript:;">Enable Split View Mode</a>
                                                <a className="dropdown-item" href="javascript:;">Keyboard Shortcuts</a>
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" href="javascript:;">Sign Out</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3"></div>
                                    <div className="input-group input-group-sm"><span className="input-group-text"><i
                                        className="bx bx-search"></i></span>
                                        <input type="text" className="form-control" placeholder="People, groups, &amp; messages" /> <span
                                            className="input-group-text"><i className="bx bx-dialpad"></i></span>
                                    </div>

                                </div>
                                <div className="chat-sidebar-content">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-Chats">
                                            <div className="p-3">
                                                <div className="meeting-button d-flex justify-content-between">
                                                    <div className="dropdown"><a href="#"
                                                                                 className="btn btn-light btn-sm radius-30 dropdown-toggle dropdown-toggle-nocaret"
                                                                                 data-bs-toggle="dropdown"><i
                                                        className="bx bx-video me-2"></i>Meet Now<i
                                                        className="bx bxs-chevron-down ms-2"></i></a>
                                                        <div className="dropdown-menu"><a className="dropdown-item" href="#">Host a
                                                            meeting</a>
                                                            <a className="dropdown-item" href="#">Join a meeting</a>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown"><a href="#"
                                                                                 className="btn btn-light btn-sm radius-30 dropdown-toggle dropdown-toggle-nocaret"
                                                                                 data-bs-toggle="dropdown" data-display="static"><i
                                                        className="bx bxs-edit me-2"></i>New Chat<i
                                                        className="bx bxs-chevron-down ms-2"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right"><a className="dropdown-item"
                                                                                                              href="#">New Group
                                                            Chat</a>
                                                            <a className="dropdown-item" href="#">New Moderated Group</a>
                                                            <a className="dropdown-item" href="#">New Chat</a>
                                                            <a className="dropdown-item" href="#">New Private Conversation</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dropdown mt-3"><a href="#"
                                                                                  className="text-uppercase text-secondary dropdown-toggle dropdown-toggle-nocaret"
                                                                                  data-bs-toggle="dropdown">Recent Chats <i
                                                    className="bx bxs-chevron-down"></i></a>
                                                    <div className="dropdown-menu"><a className="dropdown-item" href="#">Recent
                                                        Chats</a>
                                                        <a className="dropdown-item" href="#">Hidden Chats</a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="#">Sort by Time</a>
                                                        <a className="dropdown-item" href="#">Sort by Unread</a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="#">Show Favorites</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chat-list ps ps--active-y">
                                                <div className="list-group list-group-flush">
                                                    <a href="javascript:" className="list-group-item active">
                                                        <div className="d-flex">
                                                            <div className="chat-user-online">
                                                                <img src="https://via.placeholder.com/110x110"
                                                                     className="rounded-circle" alt="" width="42" height="42" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-2">
                                                                <h6 className="mb-0 chat-title">Harvey Specter</h6>
                                                                <p className="mb-0 chat-msg">Wrong. You take the gun....</p>
                                                            </div>
                                                            <div className="chat-time">4:32 PM</div>
                                                        </div>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-header d-flex align-items-center">
                                <div className="chat-toggle-btn"><i className="bx bx-menu-alt-left"></i>
                                </div>
                                <div>
                                    <h4 className="mb-1 font-weight-bold">Harvey Inspector</h4>
                                    <div className="list-inline d-sm-flex mb-0 d-none"><a href="javascript:;"
                                                                                          className="list-inline-item d-flex align-items-center text-secondary"><small
                                        className="bx bxs-circle me-1 chart-online"></small>Active Now</a>
                                        <a href="javascript:;" className="list-inline-item d-flex align-items-center">|</a>
                                        <a href="javascript:;" className="list-inline-item d-flex align-items-center"><i
                                            className="bx bx-images me-1"></i>Gallery</a>
                                        <a href="javascript:;" className="list-inline-item d-flex align-items-center">|</a>
                                        <a href="javascript:;" className="list-inline-item d-flex align-items-center"><i
                                            className="bx bx-search me-1"></i>Find</a>
                                    </div>
                                </div>
                                <div className="chat-top-header-menu ms-auto"><a href="javascript:;"><i className="bx bx-video"></i></a>
                                    <a href="javascript:;"><i className="bx bx-phone"></i></a>
                                    <a href="javascript:;"><i className="bx bx-user-plus"></i></a>
                                </div>
                            </div>
                            <div className="chat-content ps ps--active-y">
                                <div className="chat-content-leftside">
                                    <div className="d-flex">
                                        <img src="https://via.placeholder.com/110x110" className="rounded-circle" alt="" width="48"
                                             height="48" />
                                        <div className="flex-grow-1 ms-2">
                                            <p className="mb-0 chat-time">Harvey, 2:35 PM</p>
                                            <p className="chat-left-msg">Hi, harvey where are you now a days?</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-content-rightside">
                                    <div className="d-flex ms-auto">
                                        <div className="flex-grow-1 me-2">
                                            <p className="mb-0 chat-time text-end">you, 2:37 PM</p>
                                            <p className="chat-right-msg">I am in USA</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="chat-footer d-flex align-items-center">
                                <div className="flex-grow-1 pe-2">
                                    <div className="input-group"><span className="input-group-text"><i
                                        className="bx bx-smile"></i></span>
                                        <input type="text" className="form-control" placeholder="Type a message" />
                                    </div>
                                </div>
                                <div className="chat-footer-menu"><a href="javascript:;"><i className="bx bx-file"></i></a>
                                    <a href="javascript:;"><i className="bx bxs-contact"></i></a>
                                    <a href="javascript:;"><i className="bx bx-microphone"></i></a>
                                    <a href="javascript:;"><i className="bx bx-dots-horizontal-rounded"></i></a>
                                </div>
                            </div>

                            <div className="overlay chat-toggle-btn-mobile"></div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Message;