import {Link, useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

function Header(){

    const [cookies, setCookie, deleteCookie]      = useCookies(["token"]);
    const navigate                  = useNavigate();

    function relogin(){
        deleteCookie('token');
        deleteCookie('id');
        deleteCookie('fio');
        navigate('/');
    }

    return(
        <div className="topbar d-flex align-items-center">
            <nav className="navbar navbar-expand">
                <div className="mobile-toggle-menu"><i className='bx bx-menu'></i>
                </div>
                <div className="top-menu ms-auto">
                </div>
                <div className="user-box dropdown">
                    <a className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#"
                       role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://via.placeholder.com/110x110" className="user-img" alt="user avatar"/>
                            <div className="user-info ps-3">
                                <p className="user-name mb-0">{cookies.fio}</p>

                            </div>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <Link to={'/profile'} >
                            <a className="dropdown-item" href="javascript:;"><i
                            className="bx bx-user"></i><span>Profile</span></a>
                            </Link>
                        </li>

                        <li>
                            <div className="dropdown-divider mb-0"></div>
                        </li>
                        <li>
                            <a className="dropdown-item" onClick={relogin}><i
                            className='bx bx-log-out-circle'></i><span>Logout</span></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default Header;