import Navigation from "../Navigation";
import Header from "../Header";
import React, {useState} from "react";
import BreadCrum from "../BreadCrum";
import axios from "axios";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import ListUser from "../ListUser";

function Profile(){

    const [data, setData] = useState({
        position:"",
        experience:"",
        language:"",
        technologies:"",
    });
    const [prof, setProf] = useState({
        github: "",
        linkedin: "",
        website: "",
        fio:"",
    });
    const [prjct, setPrjct] = useState([]);
    const [cookies, setCookie, deleteCookie]      = useCookies(["token"]);
    const [flag, setFlag] = useState(1);

    if(flag){
        var user_id = cookies.id;
        axios.post(`http://5.101.179.244:8000/user-resume/${user_id}`)
            .then(res => {
                setData(res.data);
                setFlag(0);
            })
        axios.post(`http://5.101.179.244:8000/project-user/${user_id}`)
            .then(res => {
                setPrjct(res.data);
            })
        axios.post(`http://5.101.179.244:8000/profile/${user_id}`)
            .then(res => {
                var dd = JSON.parse(res.data.links);
                setProf({
                    fio: res.data.fio,
                    github: dd.github,
                    linkedin: dd.linkedin,
                    website: dd.website,
                });
            })
    }

    function valform(e){

        var position = e.target.id == 'uPosition' ? e.target.value : data.position;
        var experience = e.target.id == 'uExperience' ? e.target.value : data.experience;
        var language = e.target.id == 'uLanguage' ? e.target.value : data.language;
        var technologies = e.target.id == 'uTechnologies' ? e.target.value : data.technologies;

        setData({
            position: position,
            experience: experience,
            language: language,
            technologies: technologies,
        })
        console.log(e.target.id);
    }

    function sendform(){
        var user_id = cookies.id;
        var token = cookies.token;
        axios.post(`http://5.101.179.244:8000/edit-resume`, {
                "token": token,
                "id_user": user_id,
                "resume":{
                    "position": data.position,
                    "experience": data.experience,
                    "language": data.language,
                    "technologies": data.technologies
                }
            })
            .then(res => {
                if(res.data.message == 'ok')
                    alert('ok');
                console.log(res.data);
            })
    }

    return (
        <>
            <Navigation></Navigation>
            <Header></Header>
            <div className={'page-wrapper'}>
                <div className={'page-content-wrapper'}>
                    <div className={'page-content'}>
                        <BreadCrum name={'profile'} title={'User profile'}></BreadCrum>
                        <div className="container">
                            <div className="main-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    <img src="https://via.placeholder.com/110x110" alt="Admin"
                                                         className="rounded-circle p-1 bg-primary" width="110" />
                                                    <div className="mt-3">
                                                        <h4>{prof.fio}</h4>


                                                    </div>
                                                </div>
                                                <hr className="my-4"/>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                        <h6 className="mb-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                                 stroke="currentColor" stroke-width="2"
                                                                 stroke-linecap="round" stroke-linejoin="round"
                                                                 className="feather feather-globe me-2 icon-inline">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                                                <path
                                                                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                                            </svg>
                                                            Website
                                                        </h6>

                                                        <span className="text-white">{prof.website}</span>

                                                    </li>

                                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                        <h6 className="mb-0"><i
                                                            className="lni lni-linkedin font-22"></i>
                                                            <line x1="2" y1="12" x2="22" y2="12"></line>
                                                            <path
                                                                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                                            Linkedin
                                                        </h6>
                                                        <span className="text-white">{prof.linkedin}</span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                                        <h6 className="mb-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                                 stroke="currentColor" stroke-width="2"
                                                                 stroke-linecap="round" stroke-linejoin="round"
                                                                 className="feather feather-github me-2 icon-inline">
                                                                <path
                                                                    d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                                            </svg>
                                                            Github
                                                        </h6>
                                                        <span className="text-white">{prof.github}</span>
                                                    </li>


                                                </ul>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-8">

                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div className="card">
                                                    <div className="card-body">
                                                        <ul className="nav nav-tabs" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <a className="nav-link active" data-bs-toggle="tab"
                                                                   href="#project" role="tab" aria-selected="false">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="tab-icon"><i
                                                                            className="bx bx-home font-18 me-1"></i>
                                                                        </div>
                                                                        <div className="tab-title">Project</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <a className="nav-link" data-bs-toggle="tab"
                                                                   href="#resume" role="tab" aria-selected="false">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="tab-icon"><i
                                                                            className="bx bx-user-pin font-18 me-1"></i>
                                                                        </div>
                                                                        <div className="tab-title">Resume</div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content py-3">
                                                            <div className="tab-pane fade active show" id="project"
                                                                 role="tabpanel">
                                                                <div className="card">
                                                                    <div className="card-body">

                                                                        <div className="table-responsive">
                                                                            <table className="table mb-0">
                                                                                <thead className="table-light">
                                                                                <tr>
                                                                                    <th>Title</th>
                                                                                    <th>Text</th>
                                                                                    <th>Status</th>

                                                                                    <th>View Details</th>

                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                { prjct.map(prs =>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div
                                                                                                className="d-flex align-items-center">

                                                                                                <div className="ms-2">
                                                                                                    <h6 className="mb-0 font-14">{prs[2]}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{prs[3]}</td>
                                                                                        <td>
                                                                                            <div
                                                                                                className="badge rounded-pill text-success bg-light-success p-2 text-uppercase px-3">
                                                                                                <i className="bx bxs-circle me-1"></i>FulFilled
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                                <button type="button" className="btn btn-light" data-bs-toggle="modal"
                                                                                                        data-bs-target={'#exampleVerticallycentered' + prs[0]}>In more detail
                                                                                                </button>
                                                                                                <div className="modal fade fix-modal" id={'exampleVerticallycentered' + prs[0]} tabIndex="-1"
                                                                                                     aria-hidden="true">
                                                                                                    <div className="modal-dialog modal-dialog-centered">
                                                                                                        <div className="modal-content">
                                                                                                            <div className="modal-header">
                                                                                                                <h5 className="modal-title">{prs[2]} </h5>
                                                                                                                <button type="button" className="btn-close"
                                                                                                                        data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div className="modal-body"><p>{prs[3]}</p>
                                                                                                                <ListUser id={prs[0]}/>
                                                                                                            </div>
                                                                                                            <div className="modal-footer">
                                                                                                                <button type="button" className="btn btn-secondary"
                                                                                                                        data-bs-dismiss="modal">Close
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>



                                                                                        </td>

                                                                                    </tr>
                                                                                )}


                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="resume" role="tabpanel">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Position</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <textarea className="form-control"
                                                                                          id="uPosition" rows="3"
                                                                                          placeholder="Junior Java Developer" value={data.position} onChange={valform}>
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Experience</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <textarea className="form-control"
                                                                                          id="uExperience" rows="3"
                                                                                          placeholder="Hackaton 2022" value={data.experience} onChange={valform}>
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Language</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <textarea className="form-control"
                                                                                          id="uLanguage" rows="3"
                                                                                          placeholder="English - B1 ..." value={data.language} onChange={valform}>
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0">Technologies</h6>
                                                                            </div>
                                                                            <div className="col-sm-9">
                                                                                <textarea className="form-control"
                                                                                          id="uTechnologies" rows="3"
                                                                                          placeholder="PHP Laravel
Bootstrap" value={data.technologies} onChange={valform}>
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-3"></div>
                                                                            <div className="col-sm-9">
                                                                                <input type="button"
                                                                                       className="btn btn-light px-4"
                                                                                       value="Save Changes" onClick={sendform}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;