import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {useCookies} from "react-cookie";

function SingUp(){

    const [data, setData] = useState({
        fio:"",
        github:"",
        website:"",
        linkedin:"",
        email:"",
        password:"",
    });

    const navigate = useNavigate();
    const [cookies, setCookie, deleteCookie]      = useCookies(["token"]);

    function valform(e){
        var fio = e.target.id == 'inputName' ? e.target.value : data.fio;
        var github = e.target.id == 'inputGithub' ? e.target.value : data.github;
        var website = e.target.id == 'inputWebsite' ? e.target.value : data.website;
        var linkedin = e.target.id == 'inputLinkedin' ? e.target.value : data.linkedin;
        var email = e.target.id == 'inputEmail' ? e.target.value : data.email;
        var password = e.target.id == 'inputPassword' ? e.target.value : data.password;

        setData({
            fio:fio,
            github:github,
            website:website,
            linkedin:linkedin,
            email:email,
            password:password,
        })
        console.log(e.target.id);
    }

    function sendform(){
        var links = {
            "github": data.github,
            "linkedin": data.linkedin,
            "website": data.website
        }
        axios.post(`http://5.101.179.244:8000/registry`, {
            "email": data.email,
            "password_hash": data.password,
            "fio" : data.fio,
            "links": links})
            .then(res => {
                if(typeof token === "undefined") {
                    navigate('/in');
                }
                else
                    alert("error login or password");
                console.log(res.data);
            })
    }

    return(
        <div className=" section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2">
                    <div className="col mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="border p-4 rounded">
                                    <div className="text-center">
                                        <h3 className="">Sign Up</h3>
                                        <p>Already have an account?
                                            <Link to={'/in'}>
                                            Sign in here
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="form-body">


                                            <div className="col-sm-12">
                                                <label htmlFor="inputLastName" className="form-label" >Name</label>
                                                <input type="text" className="form-control" id="inputName"
                                                       placeholder="Deo" value={data.fio} onChange={valform}/>
                                            </div>
                                        <br />
                                            <div className="col-sm-12">
                                                <label htmlFor="inputLastName" className="form-label">Github</label>
                                                <input type="text" className="form-control" id="inputGithub"
                                                       placeholder="Deo" value={data.github} onChange={valform}/>
                                            </div>
                                        <br />
                                            <div className="col-sm-12">
                                                <label htmlFor="inputLastName" className="form-label">Website</label>
                                                <input type="text" className="form-control" id="inputWebsite"
                                                       placeholder="Deo" value={data.website} onChange={valform} />
                                            </div>
                                        <br />
                                            <div className="col-sm-12">
                                                <label htmlFor="inputLastName" className="form-label">Linkedin</label>
                                                <input type="text" className="form-control" id="inputLinkedin"
                                                       placeholder="Deo" value={data.linkedin} onChange={valform}/>
                                            </div>
                                        <br />
                                            <div className="col-12">
                                                <label htmlFor="inputEmailAddress" className="form-label">Email
                                                    Address</label>
                                                <input type="email" className="form-control" id="inputEmail"
                                                       placeholder="example@user.com" value={data.email} onChange={valform} />
                                            </div>
                                        <br />
                                            <div className="col-12">
                                                <label htmlFor="inputChoosePassword"
                                                       className="form-label">Password</label>
                                                <div className="input-group" id="show_hide_password">
                                                    <input type="password" className="form-control border-end-0"
                                                           id="inputPassword" value={data.password} onChange={valform}
                                                           placeholder="Enter Password" /> <a href="javascript:"
                                                                                            className="input-group-text bg-transparent"><i
                                                        className='bx bx-hide'></i></a>
                                                </div>
                                            </div>
                                        <br />
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button type="submit" className="btn btn-light" onClick={sendform}><i
                                                        className='bx bx-user'></i>Sign up
                                                    </button>
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SingUp;