import {Link} from "react-router-dom";

function Navigation(){
    return (
        <div className={'sidebar-wrapper'} data-simplebar={'true'}>
            <div className="sidebar-header">
                <div>
                    <img src="/assets/images/logo-icon.png" className="logo-icon" alt="logo icon" />
                </div>
                <div>
                    <h4 className="logo-text">Dashtrans</h4>
                </div>
                <div className="toggle-icon ms-auto"><i className='bx bx-arrow-to-left'></i>
                </div>
            </div>
            <ul class={'metismenu'} id={'menu'}>
                <li>
                    <Link to={'/'} >
                        <div className="parent-icon"><i className="bx bx-cookie"></i>
                        </div>
                        <div className="menu-title">List project</div>
                    </Link>
                </li>
                <li>
                    <Link to={'/project'} >
                        <div className="parent-icon"><i className="bx bx-cookie"></i>
                        </div>
                        <div className="menu-title">Create project</div>
                    </Link>
                </li>

            </ul>
        </div>
    );
}

export default Navigation;